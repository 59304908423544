import {Renderer2, Inject, Injectable} from '@angular/core';
import { DOCUMENT } from '@angular/common'
import {environment} from "../environments/environment";
import {of} from "rxjs";

// @ts-ignore
declare var umami: umami.umami;

@Injectable({
  providedIn: 'root'
})
export class UmamiService {

  private scriptUrl = "https://umami.pospiech.dev/script.js";

  // private Umami!: any
  // private umami$: Observable<any>|null = null;

  getUmami() {
    if (umami !== undefined) {
      return of(umami);
    }

    return of(null);
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {

  }

  /**
   * Append the JS tag to the Document Body.
   * @param renderer The Angular Renderer
   * @param src The path to the script
   * @returns the script element
   */
  public loadUmami(renderer: Renderer2): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = this.scriptUrl;

    renderer.setAttribute(script, "data-website-id", environment.website_id)
    renderer.setAttribute(script, "data-domains", environment.tracking_domains.join(","))

    renderer.appendChild(this.document.body, script);

    // this.umami$ = defer(async () => {
    //   if (!this.Umami) {
    //     // @ts-ignore
    //     this.Umami = umami;
    //     console.log('umami', umami)
    //   }
    // });
    return script;
  }
}
